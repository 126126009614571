import {
  defineStore
} from 'pinia';
import axios from 'axios';

export const useWeatherStore = defineStore('weather', {
  state: () => ({
    locations: [{
      id: 1,
      name: 'Norwood',
      latitude: 42.3601,
      longitude: -71.0589,
      loading: true,
      error: null,
      temperature: null,
      temperatureUnit: null,
      observationTime: null,
      stationId: 'KOWD',
      source: 'nws',
      windSpeed: null,
      windDirection: null,
      windDirectionCardinal: null,
      windGust: null,
      tempTable: true,
    }, {
      id: 2,
      name: 'Wiscasset',
      latitude: 43.9592,
      longitude: -69.7175,
      loading: true,
      error: null,
      temperature: null,
      temperatureUnit: null,
      observationTime: null,
      stationId: 'KIWI',
      source: 'nws',
      windSpeed: null,
      windDirection: null,
      windDirectionCardinal: null,
      windGust: null,
      tempTable: true,
    }, {
      id: 3,
      name: '49Sir',
      macAddress: 'F0:08:D1:07:0C:3D', // Replace with your device's MAC address
      loading: true,
      error: null,
      temperature: null,
      observationTime: null,
      source: 'ambient',
      windSpeed: null,
      windDirection: null,
      windDirectionCardinal: null,
      windGust: null,
      tempTable: true,
    }, {
      id: 4,
      name: 'Portland',
      latitude: 43.6591,
      longitude: -70.2568,
      loading: true,
      error: null,
      tidePredictions: [],
      stationId: '8418150', // Tide station ID for Portland, ME
      source: 'tides',
      tempTable: false,
    }, {
      id: 5,
      name: 'Central M Shelf',
      stationId: '44032',
      source: 'ndbc',
      tempTable: false,
      loading: true,
      error: null,
    }, {
      id: 6,
      name: 'Penobscot Bay',
      stationId: '44033',
      source: 'ndbc',
      tempTable: false,
      loading: true,
      error: null,
    }, {
      id: 7,
      name: 'Portland Harbor',
      stationId: '44007',
      source: 'ndbc',
      tempTable: false,
      loading: true,
      error: null,
    }, {
      id: 8,
      name: 'Mantinicus',
      stationId: 'MISM1',
      source: 'ndbc',
      tempTable: false,
      loading: true,
      error: null,
    }, {
      id: 9,
      name: 'Wellesley',
      stationId: 'BOX/71,90',
      source: 'nwsForecast',
      loading: true,
      error: null,
      forecast: {},
    }, {
      id: 10,
      name: 'Boothbay',
      stationId: 'BOX/71,90',
      source: 'nwsForecast',
      loading: true,
      error: null,
      forecast: {},
    }, {
      id: 11,
      name: 'Coastal',
      source: 'marine',
      loading: true,
      error: null,
      forecast: {},
      marineForecast: [], // Add property to store marine forecast
    }, ],
    refreshInterval: 15 * 60 * 1000,
    intervalId: null,
    marineForecast: null,
  }),

  getters: {
    getLocationById: (state) => (id) => {
      return state.locations.find((loc) => loc.id === id);
    },
  },

  actions: {
    async initialize() {
      await this.fetchData();
      this.intervalId = setInterval(() => {
        console.log('Re-fetching weather data...');
        this.fetchData();
      }, this.refreshInterval);
    },  // end initialize

    async fetchData() {
      for (const location of this.locations) {
        if (location.source === 'nws') {
          await this.fetchNWSWeatherData(location);
        } else if (location.source === 'ambient') {
          await this.fetchAmbientWeatherData(location);
        } else if (location.source === 'tides') {
          await this.fetchTideData(location);
        } else if (location.source === 'ndbc') {
          await this.fetchNDBCData(location);
        } else if (location.source === 'nwsForecast') {
          await this.fetchNWSForecast(location);
        } else if (location.source === 'marine') {
          await this.fetchMarineForecast(location);
        }
      }

    },  // end fetchData


    async fetchNWSWeatherData(location) {
      try {
        const latestObservationUrl = `https://api.weather.gov/stations/${location.stationId}/observations/latest`;
        const latestObservationResponse = await fetch(latestObservationUrl);
        // console.log("in fetch NWS - ", latestObservationUrl)
        if (!latestObservationResponse.ok) {
          throw new Error(
            `Failed to get latest observation for ${location.name} from NWS API`
          );
        }

        const latestObservationData = await latestObservationResponse.json();
        location.temperature = latestObservationData.properties.temperature.value;
        location.temperatureUnit = latestObservationData.properties.temperature.unitCode.split(':')[1];
        if (location.temperatureUnit === 'degC') {
          location.temperatureUnit = 'C';
        }
        location.observationTime = new Date(
          latestObservationData.properties.timestamp
        ).toLocaleString();

        location.windSpeed = this.kphToKnots(latestObservationData.properties.windSpeed.value);
        location.windGust = this.kphToKnots(latestObservationData.properties.windGust.value);
        location.windDirection = latestObservationData.properties.windDirection.value;
        location.windDirectionCardinal = this.degreesToCardinal(location.windDirection);
      } catch (err) {
        location.error = err.message;
        console.error(err);
      } finally {
        location.loading = false;
      }
    },  // end fetchNWSWeatherData

    async fetchAmbientWeatherData(location) {
      try {
        const workerBaseUrl = `https://api.home.tabtiang.com`; // Update the base URL
        const ambientUrl = `${workerBaseUrl}/api/ambient`;
        const response = await axios.get(ambientUrl);

        const device = response.data.find(
          (d) => d.macAddress === location.macAddress
        );

        if (!device) {
          throw new Error(`Device not found for ${location.name}`);
        }

        location.temperature = device.lastData.tempf;
        location.temperatureUnit = 'degF';
        location.observationTime = new Date(device.lastData.date).toLocaleString();
        location.windSpeed = this.mphToKnots(device.lastData.windspeedmph);
        location.windGust = this.mphToKnots(device.lastData.windgustmph);
        location.windDirection = device.lastData.winddir;
        location.windDirectionCardinal = this.degreesToCardinal(location.windDirection);
      } catch (err) {
        location.error = err.message;
        console.error(err);
      } finally {
        location.loading = false;
      }
    },
    async fetchTideData(location) {
      location.loading = true;
      location.error = null;
      try {
        const now = new Date();
        const today = now
          .toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          .replace(/-/g, '');
        const stationId = location.stationId;

        const begin_date = today;
        const end_date = new Date(now.getTime() + 86400000 * 3)
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, ''); // +3 days
        const hiloUrl = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?begin_date=${begin_date}&end_date=${end_date}&station=${stationId}&product=predictions&datum=MLLW&time_zone=lst_ldt&interval=hilo&units=english&format=json`;

        const hiloResponse = await axios.get(hiloUrl);

        if (hiloResponse.status !== 200) {
          throw new Error(`Failed to fetch high/low tide data for ${location.name}`);
        }

        const allPredictions = hiloResponse.data.predictions;
        const currentTime = new Date();
        let pastPrediction = null;
        let futurePredictions = [];

        for (let i = 0; i < allPredictions.length; i++) {
          const predictionTime = new Date(allPredictions[i].t);
          if (predictionTime < currentTime) {
            pastPrediction = allPredictions[i];
          } else {
            futurePredictions.push(allPredictions[i]);
            if (futurePredictions.length >= 2) break;
          }
        }

        location.tidePredictions = [
          ...(pastPrediction ? [pastPrediction] : []),
          ...futurePredictions,
        ];

        const graphUrl = `https://api.tidesandcurrents.noaa.gov/api/prod/datagetter?begin_date=${today}&range=48&station=${stationId}&product=predictions&datum=MLLW&time_zone=lst_ldt&interval=30&units=english&format=json`;

        const graphResponse = await axios.get(graphUrl);

        if (graphResponse.status !== 200) {
          throw new Error(`Failed to fetch 30-minute interval tide data for ${location.name}`);
        }

        location.tideGraph = graphResponse.data.predictions;
      } catch (err) {
        location.error = err.message;
        console.error('Error fetching tide data:', err);
      } finally {
        location.loading = false;
      }
    },

    async fetchNDBCData(location) {
      location.loading = true;
      location.error = null;
      try {
        const workerBaseUrl = `https://api.home.tabtiang.com`; // Update the base URL
        const ndbcUrl = `${workerBaseUrl}/api/ndbc?buoyId=${location.stationId}`;
        const response = await axios.get(ndbcUrl);

        if (!response.data || response.data.length === 0) {
          throw new Error(`Failed to fetch NDBC data for ${location.name}`);
        }

        const ndbcData = response.data[0];

        location.observationTime = ndbcData.observation_time;
        location.windDirection = ndbcData.wind_direction;
        location.windDirectionCardinal = this.degreesToCardinal(ndbcData.wind_direction);
        location.windSpeed = this.msToKnots(ndbcData.wind_speed_mps);
        location.windGust = this.msToKnots(ndbcData.wind_gust_mps);
        location.temperature = ndbcData.air_temperature_c;
        location.temperatureUnit = 'degC';
        location.waveHeight = ndbcData.wave_height;
        location.waterTemperature = ndbcData.water_temperature_c;
      } catch (err) {
        location.error = err.message;
        console.error('Error fetching NDBC data:', err);
      } finally {
        location.loading = false;
      }
    },

    async fetchMarineForecast(location) {
      try {
        const workerBaseUrl = `https://api.home.tabtiang.com`; // Update with your Worker's URL
        const marineUrl = `${workerBaseUrl}/api/marine`;
        const response = await axios.get(marineUrl);
        // console.log("in fetchMarineForecast");
        if (!response.data) {
          throw new Error('Failed to fetch marine forecast data');
        }
        location.marineForecast = response.data; // Store marine forecast in the location object
        location.loading = false;
        location.error = null;
        console.log("returning marineForecast");

        location.marineForecast.forEach(item => {
          location.forecast[this.inferTimePeriod(item.time)] = item.forecast;
        });
      } catch (err) {
        location.error = err.message;
        console.error('Error fetching marine forecast:', err);
        location.loading = false;
      }
    },

    inferTimePeriod(timeString) {
      const lowerCaseTime = timeString.toLowerCase();
      const today = new Date();
      const nextSevenDays = this.getNextSevenDays()
      // console.log(nextSevenDays);
      const regex = /(Mon|Tue|Wed|Thu|Fri|Sat|Sun)/i; // Case-insensitive regex
      const match = lowerCaseTime.match(regex);

      let inferredDate = '';
      let inferredDayName = ''; // This was missing
      // let inferredDateValue = ''; 
      // const tomorrow = new Date(today);
      // tomorrow.setDate(tomorrow.getDate() + 1);

      // const dayNames = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      // const todayName = dayNames[today.getDay()];
      // const tomorrowName = dayNames[tomorrow.getDay()];
      // console.log("timeString:", timeString);
      if (lowerCaseTime.includes('today') || lowerCaseTime.includes('this')) {
        inferredDate = today.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).replace(/-/g, '');
        inferredDayName = today.toLocaleDateString('en-US', {
          weekday: 'short',
        });
        return `${inferredDate}-${inferredDayName}-Day`; 
      } else if (lowerCaseTime.includes('tonight')) {
        inferredDate = today.toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).replace(/-/g, '');
        inferredDayName = today.toLocaleDateString('en-US', {
          weekday: 'short',
        });
        return `${inferredDate}-${inferredDayName}-Night`; 
      } else if (match) {
        // return the matched date from the nextSevenDays object and Night
        inferredDate = nextSevenDays[match[0]].toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).replace(/-/g, '');
        inferredDayName = nextSevenDays[match[0]].toLocaleDateString('en-US', {
          weekday: 'short',
        });
        if (lowerCaseTime.includes('night')) {
          return `${inferredDate}-${inferredDayName}-Night`; 
        } else {
          // assume it is Day
          return `${inferredDate}-${inferredDayName}-Day`; 
        }
      } else {
        // match is false
        console.log("error matching day name in :", lowerCaseTime);
      }

      // should never get here
      console.error("returning an error in inferTimePeriod");
      return `Error`;
    },

    getNextSevenDays() {
      const daysOfWeek = {};
      const options = { weekday: 'short' };
      
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() + i); 
        const dayOfWeek = date.toLocaleDateString('en-US', options).toLowerCase();
        daysOfWeek[dayOfWeek] = date; 
      }
      return daysOfWeek;
    },

    async fetchNWSForecast(location) {
      location.loading = true;
      location.error = null;
      location.forecast = {}; // Reset forecast

      try {
        const workerBaseUrl = `https://api.home.tabtiang.com`; // Update with your Worker's URL
        const forecastUrl = `${workerBaseUrl}/api/nws/forecast?loc=${location.name}`;
        const response = await axios.get(forecastUrl);
        if (response.status !== 200) {
          throw new Error(`Failed to get NWS forecast for ${location.name}`);
        }

        const forecastData = response.data;

        forecastData.forEach(period => {
          // Add 6 hours to startTime
          const startDate = new Date(period.startTime);
          startDate.setHours(startDate.getHours() + 1);
          // console.log("startDate:", startDate);

          // Determine if it's daytime or nighttime based on the adjusted time
          const isDaytime = startDate.getHours() >= 6 && startDate.getHours() < 18;

          // Format the dateKey as YYYYMMDD-weekday-timeOfDay
          const dateKey = `${startDate.toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }).replace(/-/g, '')}-${startDate.toLocaleDateString('en-US', {
                weekday: 'short',
              })}-${isDaytime ? 'Day' : 'Night'}`;
          // console.log("dateKey:", dateKey);
          // Check if the dateKey already exists
          if (!location.forecast[dateKey]) {
            location.forecast[dateKey] = period.detailedForecast;
          } 
        });
      } catch (err) {
        location.error = err.message;
        console.error('Error fetching NWS forecast:', err);
      } finally {
        location.loading = false;
      }
    }, // end fetchNWSForecast

    kphToKnots(kph) {
      if (kph === null || kph === undefined) {
        return 'N/A';
      }
      return (kph * 0.539957).toFixed(1); // Convert km/h to knots and round to 1 decimal place
    },

    mphToKnots(mph) {
      if (mph === null || mph === undefined) {
        return 'N/A';
      }
      return (mph * 0.868976).toFixed(1); // Convert mph to knots and round to 1 decimal place
    },

    msToKnots(ms) {
      if (ms === null || ms === undefined || ms === 'MM') {
        return 'N/A';
      }
      return (ms * 1.94384).toFixed(1); // Convert m/s to knots and round to 1 decimal place
    },

    degreesToCardinal(degrees) {
      if (degrees === null) return 'N/A';
      const directions = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW',
      ];
      const index = Math.round(degrees / 22.5) % 16;
      return directions[index];
    },  // end degressToCardinal
  },  // end actions
});