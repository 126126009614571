<template>
  <div class="tide-graph-card">
    <h2>{{ location.name }} Tide Graph</h2>
    <div v-if="location.loading">Loading...</div>
    <div v-else-if="location.error">Error: {{ location.error }}</div>
    <div v-else>
      <table class="tide-table">
        <colgroup>
          <col class="tide-type-col">
          <col class="tide-time-col">
          <col class="tide-value-col">
        </colgroup>
        <tr v-for="prediction in location.tidePredictions" :key="prediction.t">
          <td>{{ prediction.type === 'H' ? 'High' : 'Low' }}</td>
          <td>{{ formatTime(prediction.t) }}</td>
          <td>{{ prediction.v }} ft</td>
        </tr>
      </table>
      <canvas ref="chartCanvas" v-show="!location.loading && !location.error"></canvas>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export default {
  name: 'TideGraphCard',
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    chartData() {
      if (!this.location.tideGraph) return null;

      return {
        labels: this.location.tideGraph.map((prediction) => new Date(prediction.t)),
        datasets: [
          {
            label: 'Tide Height (ft)',
            data: this.location.tideGraph.map((prediction) => parseFloat(prediction.v)),
            borderColor: 'blue',
            backgroundColor: 'rgba(10, 0, 255, 0.2)',
            tension: 0.4,
          },
        ],
      };
    },
  },
  watch: {
    chartData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  mounted() {
    this.createChart();
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  },
  methods: {
    // formatTime(dateTimeString) { const date = new Date(dateTimeString); return date;},
    formatTime(dateTimeString) {
      const date = new Date(dateTimeString);
      return date.toLocaleString('en-US', {
        // month: 'short',
        // day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false, // Use 12-hour format with AM/PM
      });
    },

    createChart() {
      if (this.chartData === null) return;
      const ctx = this.$refs.chartCanvas.getContext('2d');

      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: {
          responsive: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'hour',
                tooltipFormat: 'MMM d, HH:mm',
                displayFormats: {
                  hour: ' d HH:mm',
                },
              },
              title: {
                display: true,
                text: 'Time (Local)',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Tide Height (ft)',
              },
            },
          },
          plugins: {
            annotation: {
              annotations: {
                currentTime: this.createCurrentTimeAnnotation(),
              },
            },
            title: {
              display: true,
              text: `${this.location.name} Tide Predictions`,
            },
            legend: {
              display: false,
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.$nextTick(() => {
        this.createChart();
      });
    },
    createCurrentTimeAnnotation() {
      const now = new Date();
      return {
        type: 'line',
        xMin: now,
        xMax: now,
        yMin: 0, // Adjust if you have a different scale
        yMax: 10, // Adjust if you have a different scale
        borderColor: 'red',
        borderWidth: 2,
        label: {
          content: 'Current Time',
          enabled: true,
          position: 'top',
        },
      };
    },
  },

};
</script>

<style scoped>
.tide-graph-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
/*  min-width: 800px;*/
  width: 100%;
}

.tide-table {
  table-layout: fixed; /* Important for fixed column widths */
  width: 240px; /* Or set a specific width for the table */
  margin-bottom: 20px; /* Add some space between table and chart */
}

.tide-type-col {
  width: 80px; /* Adjust as needed */
}

.tide-time-col {
  width: 80px; /* Adjust as needed */
  font-weight: bold;

}

.tide-value-col {
  width: 80px; /* Adjust as needed */
}
</style>