<template>
  <div id="app">
      <h1>Current Weather</h1>
     <div class="toggle-container">
       <label>
         <input type="checkbox" v-model="showMarineData">
         Show Marine Data
       </label>
     </div>
      <div class="location-container">
            <temp-table-card :showMarineData="showMarineData" />
      </div>
      <div class="location-container">
          <forecast-card :showMarineData="showMarineData" />
      </div>
<!--       <div class="location-container">
            <wind-table-card />
      </div> -->
      <div class="radar-image">
        <img src="https://radar.weather.gov/ridge/standard/KBOX_loop.gif" alt="NWS Radar Loop">
      </div>
      <div class="radar-image">
        <img src="https://radar.weather.gov/ridge/standard/KGYX_loop.gif" alt="NWS Radar Loop">
      </div>

      <div class="location-container">
        <tide-graph-card
          v-for="locationId in locationIds.filter(id => id === 4)"
          :key="'tide-graph-' + locationId"
          :location="getLocationById(locationId)"
        ></tide-graph-card>
      </div>


  </div>
<!--   <div class="location-container">
      <marine-forecast-card />
  </div> -->
</template>

<script>

import TempTableCard from './components/TempTableCard.vue';
// import WindTableCard from './components/WindTableCard.vue';
import TideGraphCard from './components/TideGraphCard.vue';
import ForecastCard from './components/ForecastCard.vue';
// import MarineForecastCard from './components/MarineForecastCard.vue';

import { useWeatherStore } from './stores/weatherStore';
import { storeToRefs } from 'pinia';

export default {
 name: 'App',
 components: {
  // WindTableCard,
  TempTableCard,
  TideGraphCard,
  ForecastCard,
  // MarineForecastCard,
 },
 setup() {
   const weatherStore = useWeatherStore();
   weatherStore.initialize();
   const { getLocationById } = storeToRefs(weatherStore);
   
   return {
     getLocationById,
   };
 },
 data() {
   return {
     showCelsius: false,
     showMarineData: false, // Initial state of the toggle
     locationIds: [1, 2, 3, 4, 5, 6], // Array of location IDs to display
   };
 },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.location-container {
  display: flex;
  justify-content: space-around;
  border: 0px;
}

.radar-image {
  text-align: center; /* Center the image */
  margin-top: 10px; /* Add some margin below the image */
  margin-bottom: 10px; /* Add some margin below the image */
}

.radar-image img {
  max-width: 90%; /* Make the image responsive */
  height: auto;
}
</style>