<template>
  <div class="nws-forecast-card">
    <h2>NWS Forecast</h2>

    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">Error: {{ error }}</div>
    <div v-else>
      <table class="forecast-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Forecast</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(forecastData, index) in formattedForecasts" :key="index">
            <td>{{ forecastData.date }}</td>
            <td>
              <p><strong>Wellesley:</strong>{{ forecastData.wellesley }}</p>
              <p><strong>Boothbay:</strong>{{ forecastData.boothbay }}</p>

              <p v-if="showMarineData" class="marine-forecast"><strong>Coastal Water:</strong>{{ forecastData.coastal }}</p>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useWeatherStore } from '@/stores/weatherStore';
import { storeToRefs } from 'pinia';

export default {
  name: 'NWSForecastCard',
  setup() {
    const weatherStore = useWeatherStore();
    const { locations } = storeToRefs(weatherStore);

    return {
      locations,
    };
  },
  props: {
     showMarineData: Boolean,
     // ... other props
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    formattedForecasts() {
      const wellesley = this.locations.find(loc => loc.name === 'Wellesley');
      const boothbay = this.locations.find(loc => loc.name === 'Boothbay');
      const coastal = this.locations.find(loc => loc.name === 'Coastal');
      // console.log("Wellesley", wellesley);
      // console.log("Coastal:", coastal);
      if (!wellesley || !boothbay || !wellesley.forecast || !boothbay.forecast) {
        return [];
      }

      // Assuming both locations have the same date keys, 
      // we'll use the keys from Wellesley's forecast
      return Object.keys(wellesley.forecast).map(dateKey => ({
        date: this.formatDateKey(dateKey),
        wellesley: wellesley.forecast[dateKey] || 'N/A',
        boothbay: boothbay.forecast[dateKey] || 'N/A',
        coastal: coastal.forecast[dateKey] || 'N/A',
      })).filter(
        (forecastData) => forecastData.coastal !== 'N/A'
      );
    },
  },
  methods: {
    formatDateKey(dateKey) {
      // console.log("formatting:", dateKey);
      if (typeof dateKey !== 'string') {
        console.error('dateKey is not a string:', dateKey);
        return 'Invalid Date';
      }

      const parts = dateKey.split('-');
      if (parts.length < 3) {
        console.error('Invalid dateKey format:', dateKey);
        return 'Invalid Date';
      }

      const dayNight = parts.pop();
      const weekDay = parts.pop();
      const monthDay = parts.pop().slice(-2);

      return `${weekDay} ${monthDay} ${dayNight}`;
    },
  },
  watch: {
    locations: {
      handler(newLocations) {
        this.loading = newLocations.some(loc => loc.loading && loc.source === 'nwsForecast');
        this.error = newLocations.some(loc => loc.error && loc.source === 'nwsForecast')
          ? newLocations.find(loc => loc.error && loc.source === 'nwsForecast').error
          : null;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.nws-forecast-card {
  border: 0px solid #ccc;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-size: 0.9em;
}

.forecast-table {
  width: 95%;
  border-collapse: collapse;
}

.forecast-table table td strong { /* Target strong tags within table cells */
  font-weight: bold; 
}

.forecast-table p { 
  margin-top: 2px;
  margin-bottom: 5px;
}

.marine-forecast {
  background-color: lightblue; /* Or any shade of blue you prefer */
}

.forecast-table th,
.forecast-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ccc;
}

.loading,
.error {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>