<template>
  <div class="temp-table-card">
    <h4>Temperature Information</h4>
    <table class="temperature-table">
      <thead>
        <tr>
          <th>Location</th>
          <th>T (°C)</th>
          <th>T (°F)</th>
          <th>Wind kts</th>
          <th>Gust</th>
          <th>Dir</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="location in locations" :key="location.id">
          <tr
            v-if="
              location.temperature &&
              (location.source !== 'ndbc' || showMarineData)
            "
            :class="{ 'ndbc-row': location.source === 'ndbc' }"
          >
          <!-- <tr v-if="location.temperature && (location.source !== 'ndbc' || showMarineData)"> -->
            <td>{{ location.name }}</td>
            <td>{{ toCelsius(location.temperature, location.temperatureUnit) }}</td>
            <td>{{ toFahrenheit(location.temperature, location.temperatureUnit) }}</td>
            <td>{{ location.windSpeed }}</td>
            <td>{{ location.windGust || 'N/A' }}</td>
            <td>{{ location.windDirectionCardinal }}</td>
            <td>{{ formatRetrievalTime(location.observationTime) }}</td>
          </tr>
        </template>
<!--         <tr v-for="location in locations" :key="location.id">
          <td>{{ location.name }}</td>
          <td>{{ toCelsius(location.temperature, location.temperatureUnit) }}</td>
          <td>{{ toFahrenheit(location.temperature, location.temperatureUnit) }}</td>
          <td>{{ formatRetrievalTime(location.observationTime) }}</td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import { useWeatherStore } from '@/stores/weatherStore'; // Adjust path if needed
import { storeToRefs } from 'pinia';

export default {
  name: 'TemperatureTableCard',
  props: {
     showMarineData: Boolean,
     // ... other props
  },
  setup() {
    const weatherStore = useWeatherStore();
    const { locations } = storeToRefs(weatherStore);
    // console.log("setup - ", showMarineData);
    return {
      locations,
    };
  },

  methods: {
    toCelsius(temperature, unit) {
      if (unit === 'US-UNIT' || unit === 'degF') {
        return ((temperature - 32) * 5 / 9).toFixed(1);
      } else if (unit === 'C' || unit === 'degC') {
        return temperature === null ? 'N/A' : parseFloat(temperature).toFixed(1);
      } else {
        return 'Invalid Unit';
      }
    },
    toFahrenheit(temperature, unit) {
      if (unit === 'C' || unit === 'degC') {
        return ((temperature * 9 / 5) + 32).toFixed(1);
      } else if (unit === 'US-UNIT' || unit === 'degF') {
        return temperature === null ? 'N/A' : parseFloat(temperature).toFixed(1);
      } else {
        return 'Invalid Unit';
      }
    },
    formatRetrievalTime(observationTime) {
      if (!observationTime) return 'N/A';
      const date = new Date(observationTime);
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Use 24-hour format
      });
    },
  },
};
</script>

<style scoped>



.temp-table-card {
/*  border: 3px solid #ccc;*/
  padding: 5px;
  margin: 5px;  
  border-radius: 5px;
  width: 100%;
  display: flex; /* Use flexbox to align the table */
  flex-direction: column; /* Align items vertically */
/*  background-color: green;*/
}

.temperature-table {
  width: 90%;
  border-collapse: collapse;
/*  border: 4px;*/
  font-size: 0.8em;
  margin-left: 5px; /* Add 10px left margin */
  align-self: flex-start; 
}

.temperature-table th,
.temperature-table td {
  text-align: center;
  padding: 8px;
  border: 1px solid #ccc;
}

.temperature-table tr.ndbc-row td {
  background-color: lightblue; /* Or any shade of blue you prefer */
}
</style>